import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useAddress } from "@thirdweb-dev/react";

import "./launch.css";
import LaunchStep1 from "./Step1/step1";
import LaunchStep2 from "./Step2/step2";
import LaunchStep3 from "./Step3/step3";
import LaunchMintSuccess from "./Step4.1/step4.1";
import LaunchMintFail from "./Step4.2/step4.2";
import { ThirdWebEmbedUrlChainDetails, ThirdWebMintEmbedUrlERC1155 } from "../../../../constants/common-constants";

function Launch({ nftDetails, passLimit, thirdwebClientID, programAttributes }) {
  const params = useParams();
  const navigate = useNavigate();
  const [artistId, setArtistId] = useState(nftDetails[0]["artist_id"]);
  const [membershipId, setMembershipId] = useState(nftDetails[0]["membership_id"]);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [userNfts, setUserNfts] = useState([]);
  const [passCount, setPassCount] = useState(passLimit);
  const address = useAddress();

  let [searchParams] = useSearchParams();
  const phase = searchParams.get("phase");
  const step = searchParams.get("step");
  let inviteCode = searchParams.get("invite_code");
  const [showStep, setShowStep] = useState(step);
  // console.log("launch.js searchParams >>>>>>>", phase, step, inviteCode);

  const getNavigationUrlParams = (phase, step) => {
    let urlParams = "";
    if (inviteCode) {
      urlParams = `?invite_code=${inviteCode}` + "&phase=" + String(phase) + "&step=" + String(step);
    } else {
      urlParams = "?phase=" + String(phase) + "&step=" + String(step);
    }
    return urlParams;
  };

  const changeStep = (step, data = {}) => {
    if (step == "2" && address) {
      const url = ThirdWebMintEmbedUrlERC1155(
        nftDetails[0].contract_address,
        thirdwebClientID,
        nftDetails[0].token_id,
        JSON.stringify(ThirdWebEmbedUrlChainDetails)
      );
      // console.log("mobile-launch.js thirdwebembed url>>>>>>>", url);
      window.open(url, "_self");
      changeStep(1);
      return;
    }
    if (step == "4.1" || step == "4.2" || step == "3") {
      return;
      setTransactionDetails(data);
    }
    navigate({
      pathname: "/mobile/" + String(params.membershipSlug),
      search: getNavigationUrlParams("launch", step),
    });
    setShowStep(step);
  };

  useEffect(() => {
    // console.log("mobile-launch.js nft details>>>>>", nftDetails);
    // console.log("mobile-launch.js thirdwebClientId>>>>>", thirdwebClientID);
    setShowStep(step);
  }, [step]);

  return (
    <div className="mobile-launch-container">
      <>
        <div className="mobile-launch-nft-details-container">
          {showStep == 1 ? (
            <LaunchStep1
              stepChange={changeStep}
              artistId={artistId}
              membershipId={membershipId}
              nftDetails={nftDetails[0]}
              programAttributes={programAttributes}
            />
          ) : showStep == 2 ? (
            <LaunchStep2 stepChange={changeStep} artistId={artistId} membershipId={membershipId} />
          ) : showStep == 3 ? (
            <LaunchStep3
              stepChange={changeStep}
              artistId={artistId}
              membershipId={membershipId}
              nftDetails={nftDetails[0]}
              postLaunch={false}
            />
          ) : showStep == 4.1 ? (
            <LaunchMintSuccess
              stepChange={changeStep}
              artistId={artistId}
              membershipId={membershipId}
              transactionDetails={transactionDetails}
              postLaunch={false}
              nftTitle={nftDetails[0].title}
              nftMedia={[
                { media: nftDetails[0].media, mediaType: nftDetails[0].media_type, tokenId: nftDetails[0].token_id },
              ]}
            />
          ) : showStep == 4.2 ? (
            <LaunchMintFail
              stepChange={changeStep}
              artistId={artistId}
              membershipId={membershipId}
              transactionDetails={transactionDetails}
            />
          ) : (
            ""
          )}
        </div>
      </>
    </div>
  );
}

export default Launch;
