import { useState, useEffect } from "react";
import {
  useAddress,
  useActiveClaimConditionForWallet,
  useClaimNFT,
  useContract,
  useNFTBalance,
  useChain,
  useDisconnect,
  useContractRead,
  useBalance,
} from "@thirdweb-dev/react";
import { NATIVE_TOKEN_ADDRESS } from "@thirdweb-dev/sdk";

import { ThirdWebChain } from "../../../../../constants/common-constants";

import "./step3.css";
import StepLoader from "../../../common-components/step-loader/step-loader";
import { useWalletAddress } from "../../../../../hooks/useWalletAddress";
import ThirdWebLogo from "../../../common-components/thirdweb-logo/thirdweb-logo";

function LaunchStep3({ artistId, membershipId, stepChange, nftDetails, postLaunch }) {
  // const address = useWalletAddress();
  const address = useAddress();
  const [price, setPrice] = useState(nftDetails["price"]);
  const [allowlistPrice, setAllowlistPrice] = useState(nftDetails["allowlist_price"]);
  const [nftQuantity, setNftQuantity] = useState(1);
  const [maxMintingLimitMessage, setMaxMintingLimitMessageMessage] = useState("");
  const [showTransactionRejectedErrorMessage, setShowTransactionRejectedErrorMessage] = useState(false);
  const [showInsufficientBalanceMessage, setShowInSufficientBalanceMessage] = useState(false);
  const [currentMintedTokenMessage, setCurrentMintedTokenMessage] = useState("");
  const [platformFeesDetailsLoading, setPlatformFeesDetailsLoading] = useState(true);
  const [platformFees, setPlatformFees] = useState(-1);
  const [platformFeesPercentage, setPlatformFeesPercentage] = useState(-1);
  const [showApproveTransactionMessage, setShowApproveTransactionMessage] = useState(false);

  // __CONFIG__
  const tokenAddress = NATIVE_TOKEN_ADDRESS;
  let contractDetails;
  let contractDetailsLoading;
  let contractDetailsError;

  let claimConditionForWallet;
  let claimConditionForWalletLoading;
  let claimConditionForWalletError;

  let nftBalanceForWalletData;
  let nftBalanceForWalletLoading;
  let nftBalanceForWalletError;

  let walletBalanceOfUserData;
  let walletBalanceOfUserLoading;
  let walletBalanceOfUserError;

  let maxClaimableSupplyForWallet = -1;
  let priceForWallet = -1;
  let currentNftBalanceOfWallet = -1;
  let walletBalanceOfUser = -1;

  let transactionProcessing = false;
  let transactionProcessingStatus = "";
  let transactionProcessingError = false;

  const chain = useChain();
  const disconnect = useDisconnect();

  const precisionRound = (number, precision) => {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  };

  const { contract, isLoading, error } = useContract(
    nftDetails.contract_address,
    nftDetails.token_id != "-1" ? "edition-drop" : undefined
  );

  contractDetails = contract;
  contractDetailsLoading = isLoading;
  contractDetailsError = error;
  // console.log("mobile-launch/step3.js contractDetails>>>>>>", contractDetailsLoading);
  // console.log("mobile-launch/step3.js contractDetailsError>>>>>>", contractDetailsError);
  // console.log("mobile-launch/step3.js contractDetails>>>>>>", contractDetails);

  const getPlatformDetails = async () => {
    const platformFeesData = await contract.platformFees.get();
    if (platformFeesData["platform_fee_basis_points"]) {
      setPlatformFeesPercentage(platformFeesData["platform_fee_basis_points"] / 100);
      setPlatformFeesDetailsLoading(false);
    }
  };

  if (contract) {
    getPlatformDetails();
  }

  const claimConditionForWalletRes = useActiveClaimConditionForWallet(
    contract,
    address,
    nftDetails.token_id != "-1" ? nftDetails.token_id : undefined
  );
  claimConditionForWallet = claimConditionForWalletRes["data"];
  claimConditionForWalletLoading = claimConditionForWalletRes["isLoading"];
  claimConditionForWalletError = claimConditionForWalletRes["error"];
  // console.log("mobile-launch/step3.js claimConditionOfWallet>>>>>>", claimConditionForWallet);
  // console.log("mobile-launch/step3.js claimConditionOfWalletLoading>>>>>>", claimConditionForWalletLoading);
  // console.log("mobile-launch/step3.js claimConditionOfWalletError>>>>>>", claimConditionForWalletError);
  if (claimConditionForWalletRes["data"]) {
    maxClaimableSupplyForWallet = claimConditionForWalletRes["data"]["maxClaimablePerWallet"];
    priceForWallet = hexToInt(claimConditionForWalletRes["data"]["price"]["_hex"]) / 10 ** 18;
    // console.log("mobile-launch/step3.js platformFeesPercentage>>>>>>", platformFeesPercentage);
    // console.log("mobile-launch/step3.js priceOfNftForWallet>>>>>>", priceForWallet);
    // console.log("mobile-launch/step3.js platformFees>>>>>>", (priceForWallet * platformFeesPercentage) / 100);
    if (platformFees == -1 && platformFeesPercentage != -1) {
      setPlatformFees(precisionRound((priceForWallet * platformFeesPercentage) / 100, 5));
    }
  }

  let options = [];
  if (nftDetails.token_id !== "-1") {
    options = [Number(nftDetails.token_id), Number(nftDetails.claim_condition_id), address];
  } else {
    options = [Number(nftDetails.claim_condition_id), address];
  }

  const nftBalanceOfWalletRes = useContractRead(contract, "getSupplyClaimedByWallet", options);
  nftBalanceForWalletData = nftBalanceOfWalletRes["data"];
  nftBalanceForWalletLoading = nftBalanceOfWalletRes["isLoading"];
  nftBalanceForWalletError = nftBalanceOfWalletRes["error"];
  if (nftBalanceOfWalletRes["data"]) {
    currentNftBalanceOfWallet = hexToInt(nftBalanceOfWalletRes["data"]["_hex"]);
    // console.log("mobile-launch/step3.js currentNFTBalanceOfWallet>>>>>>", currentNftBalanceOfWallet);
  }
  // console.log("mobile-launch/step3.js nftBalanceLoading>>>>>>", nftBalanceForWalletLoading);
  // console.log("mobile-launch/step3.js nftBalanceProcessingError>>>>>>", nftBalanceForWalletError);

  const tokenId = nftDetails.token_id;
  const claimNFT = useClaimNFT(contract);

  transactionProcessing = claimNFT.isLoading;
  transactionProcessingError = claimNFT.isError;
  transactionProcessingStatus = claimNFT.status;
  // console.log("mobile-launch/step3.js transaction loading>>>>>>", transactionProcessing);
  // console.log("mobile-launch/step3.js transactionHasError>>>>>>", transactionProcessingError);
  // console.log("mobile-launch/step3.js transactionProcessingStatus>>>>>>", transactionProcessingStatus);
  // console.log("mobile-launch/step3.js transactionProcessingData>>>>>>", claimNFT.data);

  const walletBalanceOfUserRes = useBalance(tokenAddress);

  walletBalanceOfUserData = walletBalanceOfUserRes["data"];
  walletBalanceOfUserLoading = walletBalanceOfUserRes["isLoading"];
  walletBalanceOfUserError = walletBalanceOfUserRes["error"];

  if (walletBalanceOfUserData) {
    walletBalanceOfUser =
      hexToInt(walletBalanceOfUserData["value"]["_hex"]) / 10 ** walletBalanceOfUserData["decimals"];
  }

  const mintNFT = async () => {
    if (transactionProcessing) {
      return;
    }
    if (currentNftBalanceOfWallet >= maxClaimableSupplyForWallet) {
      return;
    }
    // __CONFIG__
    console.log("chains >>>>>>>>", chain.chainId, ThirdWebChain.chainId);
    if (chain.chainId !== ThirdWebChain.chainId) {
      disconnect();
      stepChange(3);
      return;
    }

    if (!walletBalanceOfUserLoading && !walletBalanceOfUserError) {
      const balanceNeeded = priceForWallet * nftQuantity;
      if (walletBalanceOfUser < balanceNeeded) {
        setShowInSufficientBalanceMessage(true);
        return;
      }
    }

    setShowTransactionRejectedErrorMessage(false);
    setShowInSufficientBalanceMessage(false);
    setShowApproveTransactionMessage(true);
    transactionProcessing = true;

    claimNFT.mutate({
      to: address,
      quantity: nftQuantity,
      tokenId: nftDetails.token_id != "-1" ? parseInt(nftDetails.token_id, 10) : undefined,
    });
    // Trigger Amplitude Event
    try {
      if (window.amplitude) {
        window.amplitude.track("button_click", {
          mint_nft: nftQuantity,
        });
      }
    } catch (e) {}
  };

  function hexToInt(data) {
    return parseInt(data, 16);
  }

  const increaseNftQuantity = () => {
    if (transactionProcessing) {
      return;
    }

    setShowTransactionRejectedErrorMessage(false);
    setShowInSufficientBalanceMessage(false);
    setShowApproveTransactionMessage(false);
    let currentQuantity = nftQuantity;

    if (currentNftBalanceOfWallet >= maxClaimableSupplyForWallet) {
      setNftQuantity(0);
      return;
    }

    if (currentQuantity == maxClaimableSupplyForWallet - currentNftBalanceOfWallet) {
      return;
    }

    setNftQuantity(currentQuantity + 1);

    // Trigger Amplitude Event
    try {
      if (window.amplitude) {
        window.amplitude.track("button_click", {
          increase_nft_qty: currentQuantity + 1,
        });
      }
    } catch (e) {}
  };

  const deceaseNftQuantity = () => {
    if (transactionProcessing) {
      return;
    }
    setShowTransactionRejectedErrorMessage(false);
    setShowInSufficientBalanceMessage(false);
    setShowApproveTransactionMessage(false);
    let currentQuantity = nftQuantity;
    if (currentQuantity <= 1) return;
    setNftQuantity(currentQuantity - 1);
    // Trigger Amplitude Event
    try {
      if (window.amplitude) {
        window.amplitude.track("button_click", {
          decrease_nft_qty: currentQuantity - 1,
        });
      }
    } catch (e) {}
  };

  // this useEffect is used to check the transaction status
  // if user rejected the transaction then show the error message on the same screen
  // if there is some error while processing the transaction then change the step to 4.2 which is error screen
  // if transaction is successful then change the step to 4.1 which is success screen
  useEffect(() => {
    // console.log("transaction processing status>>>>>>>>>>", transactionProcessingStatus);
    if (claimNFT.error && claimNFT.error instanceof Error && claimNFT.error.message.indexOf("rejected") >= 0) {
      setShowApproveTransactionMessage(false);
      setShowTransactionRejectedErrorMessage(true);
      // Trigger Amplitude Event
      try {
        if (window.amplitude) {
          window.amplitude.track("status", {
            error: "user rejected",
          });
        }
      } catch (e) {}
      return;
    }
    if (transactionProcessingStatus == "error") {
      stepChange(4.2, {
        address: address,
        tokenId: nftDetails.token_id,
        contractAddress: nftDetails.contract_address,
        mintCount: nftQuantity,
        transactionHash: "",
        transactionStatus: false,
      });
      // Trigger Amplitude Event
      try {
        if (window.amplitude) {
          window.amplitude.track("status", {
            error: (claimNFT.error || {}).message || "something went wrong",
            txn: JSON.stringify(claimNFT.data || ""),
          });
        }
      } catch (e) {}
    } else if (transactionProcessingStatus == "success") {
      stepChange(4.1, {
        address: address,
        tokenId: nftDetails.token_id,
        contractAddress: nftDetails.contract_address,
        mintCount: nftQuantity,
        transactionHash:
          nftDetails.token_id === "-1"
            ? claimNFT.data[0].receipt.transactionHash
            : claimNFT.data.receipt.transactionHash,
        transactionStatus: true,
      });
      // Trigger Amplitude Event
      try {
        if (window.amplitude) {
          window.amplitude.track("status", {
            success: nftQuantity,
            txn: JSON.stringify(claimNFT.data || ""),
          });
        }
      } catch (e) {}
    }
  }, [transactionProcessingStatus]);

  // this useEffect is used to fetch the token count of the user
  // the number of token user minted already and number of token user can mint
  useEffect(() => {
    if (transactionProcessing) {
      return;
    }

    // set default nftQuantity to zero if all NFTs claimed by the user.
    if (maxClaimableSupplyForWallet >= 0 && currentNftBalanceOfWallet >= 0) {
      let defaultQty = maxClaimableSupplyForWallet - currentNftBalanceOfWallet;
      if (defaultQty == 0) {
        setNftQuantity(0);
      }
    }

    if (currentNftBalanceOfWallet > 0) {
      setCurrentMintedTokenMessage(
        `You have already minted ${currentNftBalanceOfWallet} token${currentNftBalanceOfWallet > 1 ? "s" : ""}`
      );

      let numberOfTokenLeftToMint = maxClaimableSupplyForWallet - currentNftBalanceOfWallet;
      setMaxMintingLimitMessageMessage(
        `You can mint up to ${numberOfTokenLeftToMint} token${numberOfTokenLeftToMint > 1 ? "s" : ""}`
      );
    } else {
      setMaxMintingLimitMessageMessage(
        `You can mint up to ${maxClaimableSupplyForWallet} token${maxClaimableSupplyForWallet > 1 ? "s" : ""}`
      );
    }
  }, [maxClaimableSupplyForWallet, currentNftBalanceOfWallet]);

  // if there is some error change then change the step to 1
  useEffect(() => {
    if (contractDetailsError || claimConditionForWalletError || nftBalanceForWalletError || walletBalanceOfUserError) {
      stepChange(1);
      window.location.reload();
    }
  }, [contractDetailsError, claimConditionForWalletError, nftBalanceForWalletError, walletBalanceOfUserError]);

  // if the sufficient condition for minting is not complete then change the step to 1
  // if the address is not present then change the step to 2 (wallet connect screen)
  useEffect(() => {
    if (!nftDetails.contract_address || !nftDetails.token_id || !nftDetails.claim_condition_id) {
      stepChange(1);
    }
    if (!address) {
      stepChange(2);
    }
  }, [address]);

  return (
    <>
      {contractDetailsLoading ||
      claimConditionForWalletLoading ||
      nftBalanceForWalletLoading ||
      platformFeesDetailsLoading ? (
        <StepLoader />
      ) : (
        <div className="mobile-launch-step3-container">
          <div className="mobile-launch-step3-collectors-container"></div>
          <div className="mobile-launch-step3-checkout-container">
            <div className="mobile-launch-step3-checkout-box">
              <div className="mobile-launch-step3-checkout-box-header-container">
                <div className="mobile-launch-step3-checkout-box-header-text-container">
                  <div className="mobile-launch-step3-checkout-box-header-text">Checkout</div>
                </div>
              </div>
              <div className="mobile-launch-step3-checkout-box-quantity-container">
                <div className="mobile-launch-step3-checkout-box-nft-amount-container">
                  <div className="mobile-launch-step3-checkout-box-nft-decrement-container">
                    <img
                      className="mobile-launch-step3-checkout-box-nft-minus-image"
                      src={require("../../../../../assets/minus.webp")}
                      alt="minus"
                      onClick={() => deceaseNftQuantity()}
                    />
                  </div>
                  <div className="mobile-launch-step3-checkout-box-nft-count-container">
                    <div className="mobile-launch-step3-checkout-box-nft-count-text">
                      {currentNftBalanceOfWallet >= maxClaimableSupplyForWallet ? 0 : nftQuantity}
                    </div>
                  </div>
                  <div className="mobile-launch-step3-checkout-box-nft-increment-container">
                    <img
                      className="mobile-launch-step3-checkout-box-nft-plus-image"
                      src={require("../../../../../assets/plus.webp")}
                      alt="plus"
                      onClick={() => increaseNftQuantity()}
                    />
                  </div>
                </div>
                <div className="mobile-launch-step3-checkout-box-nft-message-container">
                  <div className="mobile-launch-step3-checkout-box-nft-message-text">{maxMintingLimitMessage}</div>
                  <div className="mobile-launch-step3-checkout-box-nft-message-text">{currentMintedTokenMessage}</div>
                </div>
              </div>
              <div className="mobile-launch-step3-checkout-box-order-details-container">
                <div className="mobile-launch-step3-checkout-box-order-details-header-container">
                  <div className="mobile-launch-step3-checkout-box-order-details-header-text">Your order</div>
                </div>
                <div className="mobile-launch-step3-checkout-box-order-details-price-container">
                  <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-container">
                    <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-text-container">
                      <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-text">Price</div>
                    </div>
                    <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-price-container">
                      <div className="mobile-launch-step3-checkout-box-order-details-price-text">
                        {postLaunch ? 0 : price} ETH
                      </div>
                      {postLaunch ? (
                        ""
                      ) : priceForWallet == nftDetails["allowlist_price"] ? (
                        <div className="mobile-launch-step3-checkout-box-order-details-price-text-crossed" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {priceForWallet == nftDetails["allowlist_price"] ? (
                    <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-container">
                      <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-text-container">
                        <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-text">
                          Allowlist price
                        </div>
                      </div>
                      <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-price-container">
                        <div className="mobile-launch-step3-checkout-box-order-details-price-text">
                          {postLaunch ? 0 : allowlistPrice} ETH
                        </div>
                        {postLaunch ? (
                          ""
                        ) : priceForWallet == nftDetails["allowlist_price"] ? (
                          ""
                        ) : (
                          <div className="mobile-launch-step3-checkout-box-order-details-price-text-crossed" />
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-container">
                    <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-text-container">
                      <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-text">
                        Platform fees
                      </div>
                    </div>
                    <div className="mobile-launch-step3-checkout-box-order-details-price-breakdown-price-container">
                      <div className="mobile-launch-step3-checkout-box-order-details-price-text">
                        {platformFees} ETH
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-launch-step3-checkout-box-total-price-container">
                <div className="mobile-launch-step3-checkout-box-total-price-text-container">
                  <div className="mobile-launch-step3-checkout-box-total-price-text">Total</div>
                </div>
                <div className="mobile-launch-step3-checkout-box-total-price-value-container">
                  <div className="mobile-launch-step3-checkout-box-total-price-value-text">
                    {postLaunch
                      ? precisionRound(platformFees, 5)
                      : currentNftBalanceOfWallet >= maxClaimableSupplyForWallet
                      ? 0
                      : priceForWallet == nftDetails["allowlist_price"]
                      ? precisionRound((allowlistPrice + platformFees) * nftQuantity, 5)
                      : precisionRound((price + platformFees) * nftQuantity, 5)}{" "}
                    ETH
                  </div>
                </div>
              </div>
            </div>

            <div className="mobile-launch-step3-message-container">
              <div
                className="mobile-launch-step3-message-text"
                style={{ color: showApproveTransactionMessage ? "#000000" : "#d32f2f" }}
              >
                {showTransactionRejectedErrorMessage ? "Please approve the transaction to proceed." : ""}
                {showInsufficientBalanceMessage ? "You do not have sufficient balance in the wallet." : ""}
                {showApproveTransactionMessage ? "Please open the wallet app & approve the transaction." : ""}
              </div>
            </div>
          </div>
          <div className="mobile-launch-step3-navigation-container">
            <div className="mobile-launch-step3-button-container">
              <div
                className="mobile-launch-step3-button"
                onClick={() => mintNFT()}
                style={{ background: nftQuantity > 0 ? "#000000" : "#607d8b" }}
              >
                <div className="mobile-launch-step3-button-text">
                  {transactionProcessing ? (
                    <img
                      className="mobile-launch-step3-button-loader"
                      src={require("../../../../../assets/button-loader.gif")}
                      alt="button-loader"
                    />
                  ) : (
                    "Mint now"
                  )}
                </div>
              </div>
            </div>
            <div className="mobile-launch-step3-thirdweb-container">
              <ThirdWebLogo width="140px" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LaunchStep3;
