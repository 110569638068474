import "./nft-card-container.css";
import { useEffect, useState } from "react";
import * as React from "react";

import { useAddress } from "@thirdweb-dev/react";

import { OpenseaViewNFTTokenURL } from "../../../../constants/common-constants";
import StepButton from "../step-button/step-button";
import ThirdWebLogo from "../thirdweb-logo/thirdweb-logo";
import EventDate from "../event-date/event-date";
import WizardAPI from "../../wizard/wizard-api";
import auctionWinnerBackground from "../../../../assets/auction-winner-background-image.gif";

function NftCardContainer({
  nftDetails,
  stepChange,
  userHasPass,
  passDataLoading,
  launchNftContractAddress,
  launchNftTokenId,
  nftIndex,
  artistId,
  membershipId,
  launchNftTitle,
}) {
  const [mintDate, setMintDate] = useState({});
  const [changeStepDetails, setChangeStepDetails] = useState(true);
  const [latestEventLive, setLatestEventLive] = useState(true);
  const [email, setEmail] = useState("");
  const [removeNotification, setRemoveNotification] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [error, setError] = useState(false);
  const [isLimitedEditionRaffle, setIsLimitedEditionRaffle] = useState(false);
  const [allowlist, setAllowlist] = useState([]);
  const [disableMintButton, setDisableMintButton] = useState(false);

  const address = useAddress();

  const onValueChange = (evt) => {
    if (showButtonLoader) return;
    setEmail(evt.target.value);
  };

  const handleFocus = (e) => {
    if (showButtonLoader) return;
    setError(false);
    e.currentTarget.select();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const storeEmailAddress = async () => {
    if (showButtonLoader) return;
    setShowButtonLoader(true);
    if (email === "") {
      setShowButtonLoader(false);
      return;
    }
    if (!validateEmail(email)) {
      setError(true);
      setShowButtonLoader(false);
      return;
    }

    const storeEmailRes = await WizardAPI.storeEmail(artistId, membershipId, address, email);
    if (storeEmailRes["success"] && storeEmailRes["code"] === 200) {
      setShowButtonLoader(false);
      // setRemoveNotification(false);
      // setNotificationMessage("Done! We'll send you email reminders closer to the date of mint.");
    } else {
      setShowButtonLoader(false);
    }
  };

  const convertToLocalTimestamp = (timestamp) => {
    var preLaunchStartTime = timestamp + "Z";
    var datetime = new Date(preLaunchStartTime);
    return datetime;
  };

  const isLimitedEditionRaffleMint = (nftData) => {
    if (
      nftData["nft_mint_transaction"] &&
      nftData["nft_mint_transaction"].length &&
      nftData["nft_mint_transaction"][0] == "limited_edition_raffle"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const hasUserWonRaffle = () => {
    if (
      address &&
      userHasPass &&
      !passDataLoading &&
      isLimitedEditionRaffle &&
      allowlist.includes(address.toLowerCase()) &&
      nftIndex == 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isLatestEventLive = () => {
    const mintStartTime = convertToLocalTimestamp(nftDetails.mint_start);
    const mintEndDate = convertToLocalTimestamp(nftDetails.mint_end);
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    var now = new Date();
    var currentDateAndTime = now;
    // console.log("dates>>>>", mintStartTime, mintEndDate, currentDateAndTime);
    if (mintStartTime <= currentDateAndTime && currentDateAndTime < mintEndDate) {
      // console.log("minting live");
    } else {
      // console.log("minting not live");
      setLatestEventLive(false);
    }
  };

  const getButtonText = () => {
    let buttonText = "Mint now";
    if (!nftDetails.live && nftIndex != 0) {
      if (nftDetails.mint_url) {
        buttonText = "Bid now";
      } else {
        buttonText = "Mint now";
      }
    } else {
      if (!address) {
        buttonText = "Connect Wallet";
      } else {
        if (!userHasPass) {
          buttonText = "Buy on secondary";
        } else {
          if (nftDetails.mint_url) {
            buttonText = "Bid now";
          } else {
            buttonText = "Mint now";
          }
        }
      }
    }
    return buttonText;
  };

  useEffect(() => {
    // console.log("mobile/nft-card-container.js nftDetails>>>>", nftDetails);
    if (nftIndex == 0) {
      isLatestEventLive();
    }
    if (
      nftDetails &&
      nftDetails["nft_mint_transaction"] &&
      nftDetails["nft_mint_transaction"].length &&
      nftDetails["nft_mint_transaction"][0] == "limited_edition_raffle"
    ) {
      setIsLimitedEditionRaffle(true);
      if (nftDetails.allowlist && nftDetails.allowlist.length) {
        setAllowlist(nftDetails.allowlist);
      }
    }
  }, []);

  useEffect(() => {
    if (address && !passDataLoading && userHasPass) {
      if (isLimitedEditionRaffle && !allowlist.includes(address.toLowerCase())) {
        setDisableMintButton(true);
      } else {
        setDisableMintButton(false);
      }
    } else {
      setDisableMintButton(false);
    }
  }, [userHasPass, passDataLoading]);

  return (
    <div style={{ display: "flex" }}>
      <div className="mobile-nft-card-event-date-container">
        <EventDate
          data={{ "mint-start-time": nftDetails.mint_start, "mint-end-time": nftDetails.mint_end }}
          mint={nftDetails.mint_url ? false : true}
        />
        <div
          className="mobile-nft-card-event-date-line-container"
          style={{
            backgroundImage: nftDetails.mint_url
              ? "linear-gradient(180deg, rgba(222, 105, 161, 0.4) 0%, rgba(45, 183, 206, 0.4) 100%)"
              : "linear-gradient(180deg, rgba(45, 183, 206, 0.4) 0%, rgba(222, 105, 161, 0.4) 100%)",
          }}
        ></div>
      </div>
      <div
        className="mobile-nft-card-container"
        style={{
          boxShadow: nftDetails.live ? "0px 19.719087600708008px 39.438175201416016px 0px #8f0cf540" : "",
          border: hasUserWonRaffle() ? "1px solid #FDEA42" : "",
          backgroundImage: hasUserWonRaffle() ? `url(${auctionWinnerBackground})` : "",
          backgroundRepeat: hasUserWonRaffle() ? "no-repeat" : "",
          backgroundPosition: hasUserWonRaffle() ? "center" : "",
          backgroundSize: hasUserWonRaffle() ? "cover" : "",
        }}
      >
        <div className="mobile-nft-card-title-and-link-container">
          <div className="mobile-nft-card-link-container">
            <div
              className="mobile-nft-card-nft-type-container"
              style={{ color: nftDetails.mint_url ? "rgba(222, 105, 161, 0.25)" : "rgba(45, 183, 206, 0.25)" }}
            >
              {nftDetails.mint_url ? "auction" : isLimitedEditionRaffleMint(nftDetails) ? "gift" : "Mint"}
            </div>
            {nftDetails.token_id != "-1" ? (
              <div className="mobile-nft-card-linkout-image-container">
                <img
                  className="mobile-nft-card-linkout-image"
                  src={require("../../../../assets/linkout.webp")}
                  onClick={() => {
                    let url = `${OpenseaViewNFTTokenURL}/${nftDetails.contract_address}/${nftDetails.token_id}`;
                    window.open(url, "_blank", "noopener,noreferrer");
                  }}
                  alt="linkout"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="mobile-nft-card-title-container"
            style={{ color: nftDetails.mint_url ? "#DE69A1" : "#2db7ce" }}
          >
            {changeStepDetails ? nftDetails.title : ""}
          </div>
        </div>
        <div className="mobile-nft-card-media-or-description-container">
          {changeStepDetails ? (
            <div
              className="mobile-nft-card-media"
              style={{
                backgroundImage: nftDetails["media_type"] === "image" ? `url(${nftDetails["media"]})` : "",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              {nftDetails["media_type"] != "image" ? (
                <video className="mobile-nft-card-video " controls muted>
                  <source src={nftDetails["media"]} type="video/mp4" />
                </video>
              ) : (
                ""
              )}
            </div>
          ) : (
            <>
              <div className="mobile-nft-card-other-side-title-container">{nftDetails.title}</div>
              <div className="mobile-nft-card-other-side-desc-container">{nftDetails.description}</div>
            </>
          )}
        </div>
        <div className="mobile-nft-card-button-container">
          <div
            className="mobile-nft-card-change-step-details-container"
            onClick={() => {
              setChangeStepDetails(!changeStepDetails);
            }}
            style={{
              paddingTop:
                (nftDetails.live && address && !passDataLoading && !userHasPass) ||
                (nftIndex == 0 &&
                  address &&
                  userHasPass &&
                  !passDataLoading &&
                  isLimitedEditionRaffle &&
                  !allowlist.includes(address.toLowerCase()))
                  ? "20px"
                  : "",
            }}
          >
            <img
              className="mobile-nft-card-change-step-details-image"
              src={require("../../../../assets/flip.webp")}
              alt="flip"
            />
          </div>
          {nftIndex == 0 && address && !userHasPass && !passDataLoading ? (
            <div className="mobile-nft-card-pass-not-find-text-container ">
              <div className="mobile-nft-card-pass-not-find-text">You don't have access to this</div>
            </div>
          ) : (
            ""
          )}

          {nftIndex == 0 &&
          address &&
          userHasPass &&
          !passDataLoading &&
          isLimitedEditionRaffle &&
          !allowlist.includes(address.toLowerCase()) ? (
            <div className="mobile-nft-card-pass-not-find-text-container ">
              <div className="mobile-nft-card-pass-not-find-text">
                Sorry! You don't have access to mint this edition.
              </div>
            </div>
          ) : (
            ""
          )}

          {nftIndex == 0 && address && !passDataLoading && userHasPass && !latestEventLive ? (
            <div className="mobile-nft-card-email-container ">
              <div className="mobile-nft-card-email-text-container">
                <div className="mobile-nft-card-email-text">Get notified of the next drop.</div>
              </div>
              <div className="mobile-nft-card-email-input-box-container">
                <input
                  className="mobile-nft-card-email-input-box"
                  defaultValue={email}
                  placeholder="Enter email address"
                  onInputCapture={onValueChange}
                  onFocus={handleFocus}
                  disabled={showButtonLoader}
                  autoFocus
                />
                <div onClick={storeEmailAddress}>
                  <StepButton buttonText="Next" width="40px" height="40px" loading={showButtonLoader} />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{ paddingTop: "10px" }}
                onClick={() => {
                  if (!nftDetails.live && nftIndex != 0) {
                    return;
                  }
                  if (passDataLoading) {
                    return;
                  }
                  if (address) {
                    if (!userHasPass) {
                      let url = `${OpenseaViewNFTTokenURL}/${nftDetails.contract_address}`;
                      window.open(url, "_blank", "noopener,noreferrer");
                    } else {
                      if (isLimitedEditionRaffle && !allowlist.includes(address.toLowerCase())) {
                        return;
                      }
                      stepChange(3);
                    }
                  } else {
                    stepChange(2);
                  }
                }}
              >
                <StepButton
                  buttonText={getButtonText()}
                  width="150px"
                  height="45px"
                  loading={!nftDetails.live && nftIndex != 0 ? false : passDataLoading}
                  disable={nftIndex == 0 ? disableMintButton : !nftDetails.live}
                />
              </div>

              <ThirdWebLogo width="140px" />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NftCardContainer;
