import { useState, useEffect } from "react";
import "./step4.1.css";
import { useAddress } from "@thirdweb-dev/react";
import Confetti from "react-confetti";
import StepButton from "../../../common-components/step-button/step-button";
import { EtherscanTransactionURL } from "../../../../../constants/common-constants";

function LaunchMintSuccess({ artistId, membershipId, stepChange, transactionDetails, postLaunch, nftTitle, nftMedia }) {
  const address = useAddress();

  useEffect(() => {
    // console.log("launch/step4.1.js transactionDetails>>>", transactionDetails);
    // console.log("launch.step4.1.js>>>", nftMedia);
  }, []);

  return (
    <>
      <div style={{ zIndex: -1, position: "absolute", width: "100vw", height: "100vh" }}>
        <Confetti
          numberOfPieces={500}
          recycle={false}
          colors={[
            "#E4F1E8",
            "#C8E6E4",
            "#66C6CC",
            "#00A0AA",
            "#5999B2",
            "#EEA8B8",
            "#E1CAE1",
            "#937493",
            "#DE69A1",
            "#EA6383",
            "#FEA86D",
            "#FCD465",
            "#FFEC3F",
            "#FFF597",
            "#FFDE9D",
            "#F5AFD1",
            "#FFEDF6",
          ]}
        />
      </div>

      <div className="mobile-launch-mint-success" style={{ justifyContent: !nftMedia[0]["media"] ? "center" : "" }}>
        <div className="mobile-launch-mint-success-message-heading-text">CONGRATULATIONS!</div>
        <div className="mobile-launch-mint-success-message-secondary-text">
          {postLaunch ? `You have minted ${nftTitle}.` : `You now own ${nftTitle}.`}
        </div>
        <div
          className="mobile-launch-mint-success-details-nft-media-container"
          style={{ height: nftMedia[0]["media"] ? "55%" : "" }}
        >
          {!nftMedia[0]["media"] ? (
            ""
          ) : nftMedia[0]["mediaType"] === "image" ? (
            <img style={{ height: "90%" }} src={nftMedia[0]["media"]} />
          ) : (
            <video height="90%" controls muted>
              <source src={nftMedia[0]["media"]} type="video/mp4" />
            </video>
          )}
        </div>

        <div className="mobile-launch-mint-success-message-container">
          <div
            onClick={() => {
              let url = `${EtherscanTransactionURL}/${transactionDetails.transactionHash}`;
              window.open(url, "_blank");
            }}
            className="mobile-launch-mint-success-transaction-url-container"
          >
            View on Etherscan
          </div>
        </div>
        <div className="mobile-launch-mint-success-navigation-container">
          <div className="mobile-launch-mint-success-button-container">
            <div
              onClick={() => {
                stepChange(1);
              }}
            >
              <StepButton buttonText={"Continue to Home"} width="150px" height="45px" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LaunchMintSuccess;
