import { API } from "../../../constants/common-constants";

function WizardAPI() {
  async function getStepDetails(artistId, membershipId, phase, step) {
    var data = JSON.stringify({
      artist_id: artistId,
      membership_id: membershipId,
      phase: phase,
      step_number: step,
    });
    const res = await fetch(API.GetStepDetails, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("step details>>>>>", response);
    return response;
  }

  async function getMemberDetails(artistId, membershipId, wallet, refereeCode) {
    var data = JSON.stringify({
      artist_id: artistId,
      membership_id: membershipId,
      wallet: wallet,
      referee_code: refereeCode,
    });
    const res = await fetch(API.GetMemberDetails, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("step details>>>>>", response);
    return response;
  }

  async function storeEmail(artistId, membershipId, wallet, email) {
    var data = JSON.stringify({
      artist_id: artistId,
      membership_id: membershipId,
      wallet: wallet,
      email: email,
    });
    const res = await fetch(API.StoreEmailAddress, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("step details>>>>>", response);
    return response;
  }

  async function validateInviteCode(artistId, membershipId, wallet, refereeCode) {
    var data = JSON.stringify({
      artist_id: artistId,
      membership_id: membershipId,
      wallet: wallet,
      referee_code: refereeCode,
    });
    const res = await fetch(API.ValidateInviteCode, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("step details>>>>>", response);
    return response;
  }

  async function storeTransactionDetails(
    artistId,
    membershipId,
    wallet,
    tokenId,
    contractAddress,
    mintCount,
    transactionHash,
    transactionStatus
  ) {
    var data = JSON.stringify({
      artist_id: artistId,
      membership_id: membershipId,
      wallet: wallet,
      token_id: tokenId,
      contract_address: contractAddress,
      mint_count: mintCount,
      transaction_hash: transactionHash,
      transaction_status: transactionStatus,
    });

    const res = await fetch(API.StoreTransactionDetails, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    return response;
  }

  async function getCollectorDetails(artistId, membershipId, dropNumber) {
    var data = JSON.stringify({
      artist_id: artistId,
      membership_id: membershipId,
      drop_number: dropNumber,
      is_single_token: true,
    });

    const res = await fetch(API.GetCollectorDetails, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    return response;
  }

  return {
    getStepDetails,
    getMemberDetails,
    storeEmail,
    validateInviteCode,
    storeTransactionDetails,
    getCollectorDetails,
  };
}

export default WizardAPI();
