import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Mobile from "./mobile/mobile";
import { ThirdWebChain, ThirdWebClientID } from "./constants/common-constants";
import { metamaskWallet, walletConnect, coinbaseWallet } from "@thirdweb-dev/react";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { TasseoCupLoader } from "./components/common-components/tasseo-cup-loader/tasseo-cup-loader";
import phaseDetailsApi from "./api/phase-details-api";
import { Ethereum, Sepolia, BaseSepoliaTestnet, Base } from "@thirdweb-dev/chains";

const root = ReactDOM.createRoot(document.getElementById("root"));

let thirdwebClientId = ThirdWebClientID;

const setTitleAndDescription = (title, description) => {
  document.title = title;
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", description);
  }
};

// Render default website loader
root.render(
  <React.StrictMode>
    <TasseoCupLoader></TasseoCupLoader>
  </React.StrictMode>
);

// Rerender with ThirdWebClientID
const reRender = (ThirdWebClientID) => {
  root.render(
    <React.StrictMode>
      <ThirdwebProvider
        supportedWallets={[metamaskWallet(), coinbaseWallet(), walletConnect()]}
        activeChain={ThirdWebChain}
        clientId={ThirdWebClientID}
        supportedChains={[Base, Ethereum, Sepolia, BaseSepoliaTestnet]}
      >
        <Router>
          <Routes>
            <Route path="/:membershipSlug" element={<App />} />
            <Route path="/mobile/:membershipSlug" element={<Mobile thirdWebClientID={thirdwebClientId} />} />
            <Route path="/error" element={<App />} />
            <Route path="*" element={<Navigate to="/error" replace />} />
          </Routes>
        </Router>
      </ThirdwebProvider>
    </React.StrictMode>
  );
};

try {
  let artistDomain = (window.location.hostname || ".").split(".")[0];
  let membershipSlug = (window.location.pathname || "").replace("/", "").replace("mobile/", "");
  console.log(artistDomain, membershipSlug);
  let websiteInitialData = await phaseDetailsApi.getInitialData(artistDomain, membershipSlug);

  if (
    websiteInitialData["success"] &&
    websiteInitialData["code"] == 200 &&
    websiteInitialData["data"] &&
    websiteInitialData["data"]["thirdweb_client_id"] &&
    websiteInitialData["data"]["title"] &&
    websiteInitialData["data"]["description"]
  ) {
    thirdwebClientId = websiteInitialData["data"]["thirdweb_client_id"];
    setTitleAndDescription(websiteInitialData["data"]["title"], websiteInitialData["data"]["description"]);
    reRender(thirdwebClientId);
  } else {
    console.log("Something went wrong.", websiteInitialData);
    reRender(ThirdWebClientID); // use a fallback clientID
  }
} catch (e) {
  console.log("Something went wrong.", e);
  reRender(ThirdWebClientID); // use a fallback clientID
}

// TODO: send this to amplitude
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// function sendToAnalytics({ id, name, value }) {
//   ga('send', 'event', {
//     eventCategory: 'Web Vitals',
//     eventAction: name,
//     eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//     eventLabel: id, // id unique to current page load
//     nonInteraction: true, // avoids affecting bounce rate
//   });
// }
// reportWebVitals(sendToAnalytics);
//
