import { useState, useEffect } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./step1.css";
import { useAddress } from "@thirdweb-dev/react";
import WizardAPI from "../../wizard-api";
import StepLoader from "../../../common-components/step-loader/step-loader";
import MembershipDescTicketPartOne from "../../../common-components/membership-desc-ticket-part-one/membership-desc-ticket-part-one";
import MembershipDescTicketPartTwo from "../../../common-components/membership-desc-ticket-part-two/membership-desc-ticket-part-two";
import MembershipDescTicketPartTwoForAllowlist from "../../../common-components/membership-desc-ticket-part-two-for-allowlist/membership-desc-ticket-part-two-for-allowlist";
import ThirdWebLogo from "../../../common-components/thirdweb-logo/thirdweb-logo";
import EventDate from "../../../common-components/event-date/event-date";
import StepButton from "../../../common-components/step-button/step-button";
import { useWalletAddress } from "../../../../../hooks/useWalletAddress";

function LaunchStep1({ artistId, membershipId, stepChange, nftDetails, programAttributes }) {
  const _HexValues = ["4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E"];
  const [loading, setLoading] = useState(true);
  const [stepDetails, setStepDetails] = useState({});
  const [allowlisted, setAllowlisted] = useState(false);
  const [collectors, setCollectors] = useState([]);
  const [changeStepDetails, setChangeStepDetails] = useState(true);
  const [mintTarget, setMintTarget] = useState(-1);
  const [tokenMinted, setTokenMinted] = useState(-1);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [mintingEnded, setMintingEnded] = useState(false);
  const address = useWalletAddress();

  const fetchStepDetails = async () => {
    setLoading(true);
    const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "launch", 1);
    // console.log("launch.js./step1.js step details>>>>>", stepDetailsRes["data"]);
    if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
      setStepDetails(stepDetailsRes["data"]);
    } else {
      alert("Something went wrong. Please refresh page.");
    }
    await fetchCollectorsDetails();
  };

  const fetchCollectorsDetails = async () => {
    const collectorsDetailsRes = await WizardAPI.getCollectorDetails(artistId, membershipId, nftDetails.drop_number);
    // console.log("mobile/launch.js./step1.js collectors details>>>>>>>>>>", collectorsDetailsRes);
    if (collectorsDetailsRes["success"] && collectorsDetailsRes["code"] === 200) {
      const data = collectorsDetailsRes["data"];
      let collectorList = data && data.length ? data[0]["collectors"] : [];
      setCollectors(collectorList);
      if (data && data.length && "token_count" in data[0]) {
        setTokenMinted(data[0]["token_count"]);
      }
      setLoading(false);
    }
  };

  const getGradient = () => {
    let color1 = "#";
    for (let i = 0; i < 6; i++) {
      let x = Math.round(Math.random() * (_HexValues.length - 1));
      let y = _HexValues[x];
      color1 += y;
    }
    let color2 = "#";
    for (let i = 0; i < 6; i++) {
      let x = Math.round(Math.random() * (_HexValues.length - 1));
      let y = _HexValues[x];
      color2 += y;
    }
    // keep light color first
    if (color1 < color2) {
      let t = color1;
      color1 = color2;
      color2 = t;
    }
    let angle = Math.round(Math.random() * 100);
    return "linear-gradient(" + angle + "deg, " + color1 + ", " + color2 + ")";
  };

  // -------------------------------------------------
  // useEffects
  // -------------------------------------------------

  useEffect(() => {
    if (collectors.length < 7) return;
    setTimeout(() => {
      try {
        // console.log("changing class...");
        let el0 = document.getElementById("collector-pfp-0");
        let el5 = document.getElementById("collector-pfp-5");
        el0.className = "launch-step1-collector-pfp launch-step1-collector-pfp-zero-size";
        el5.className = "launch-step1-collector-pfp launch-step1-collector-pfp-full-size";
      } catch (e) {
        //
      }
    }, 1300);
  }, [collectors]);

  useEffect(() => {
    // console.log("launch/step1.js>>>>>>>>>>", nftDetails);
    if (programAttributes && "program-start-mint-target" in programAttributes) {
      setMintTarget(programAttributes["program-start-mint-target"]);
    }
    if (programAttributes && "show-progress-bar" in programAttributes) {
      setShowProgressBar(programAttributes["show-progress-bar"]);
    }
    if (programAttributes && "mint-end-time" in programAttributes) {
      const now = new Date();
      let mintEndTime = new Date(programAttributes["mint-end-time"]);
      if (now > mintEndTime) {
        setMintingEnded(true);
      }
    }
    fetchStepDetails();
  }, []);

  useEffect(() => {
    if (address) {
      WizardAPI.getMemberDetails(artistId, membershipId, address, "").then((response) => {
        if (response && response.data && response.data.on_allowlist === true) setAllowlisted(true);
      });
    } else {
      setAllowlisted(false);
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="mobile-launch-step1-container">
          <div className="mobile-launch-event-date-container">
            <EventDate data={stepDetails} mint={true} />
            <div className="mobile-launch-event-date-line-container"></div>
            <div className="mobile-launch-event-date-end-container"></div>
          </div>

          <div className="mobile-launch-step1-card-container">
            <div className="mobile-launch-step1-card-title-container ">
              <div className="mobile-launch-step1-card-title ">{nftDetails.title}</div>
            </div>

            {changeStepDetails ? (
              <div className="mobile-launch-step1-card-media-container">
                <div
                  className="mobile-launch-step1-card-media"
                  style={{
                    backgroundImage: nftDetails["media_type"] === "image" ? `url(${nftDetails["media"]})` : "",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                >
                  {nftDetails["media_type"] != "image" ? (
                    <video className="mobile-launch-step1-card-video " controls muted>
                      <source src={nftDetails["media"]} type="video/mp4" />
                    </video>
                  ) : (
                    ""
                  )}
                </div>
                {showProgressBar ? (
                  <div className="mobile-launch-step1-progress-bar-container">
                    <ProgressBarComponent tokenMinted={String(tokenMinted)} mintTarget={mintTarget} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="mobile-launch-step1-details-container">
                <div className="mobile-launch-step1-membership-desc-ticket-part-one-container">
                  <MembershipDescTicketPartOne data={stepDetails} launch={true} />
                </div>
                <div className="mobile-launch-step1-membership-sub-desc-container">
                  <div className="mobile-launch-step1-membership-sub-desc">
                    {stepDetails["membership-sub-description"]}
                  </div>
                </div>
                <div className="mobile-launch-step1-membership-desc-ticket-part-two-container">
                  {allowlisted ? (
                    <MembershipDescTicketPartTwoForAllowlist data={stepDetails} launch={true} />
                  ) : (
                    <MembershipDescTicketPartTwo data={stepDetails} launch={true} />
                  )}
                </div>
                {showProgressBar ? (
                  <div className="mobile-launch-step1-progress-bar-container">
                    <ProgressBarComponent tokenMinted={String(tokenMinted)} mintTarget={mintTarget} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="mobile-launch-step1-card-button-container">
              <div
                className="mobile-launch-step1-change-step-details-container"
                onClick={() => {
                  setChangeStepDetails(!changeStepDetails);
                }}
              >
                <img
                  className="mobile-launch-step1-change-step-details-image"
                  src={require("../../../../../assets/flip.webp")}
                  alt="flip"
                />
              </div>
              <div className="mobile-launch-step1-allowlist-price-container">{stepDetails["allowlist-text"]}</div>
              <div
                onClick={() => {
                  if (!mintingEnded) {
                    stepChange(2);
                  } else {
                  }
                }}
              >
                <StepButton
                  buttonText={address ? "Mint" : "Connect Wallet"}
                  width="150px"
                  height="45px"
                  disable={mintingEnded}
                />
              </div>

              <ThirdWebLogo width="140px" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export const ProgressBarComponent = ({ tokenMinted, mintTarget }) => {
  return (
    <ProgressBar
      completed={tokenMinted}
      bgColor="#2DB7CE"
      baseBgColor="#e0ddea"
      height="12px"
      isLabelVisible={true}
      labelSize="8px"
      labelColor="#000000"
      maxCompleted={mintTarget}
      borderRadius="10px"
    />
  );
};

export default LaunchStep1;
