import { useState, useEffect } from "react";
import WizardAPI from "../../wizard-api";
import "./step2.css";
import StepLoader from "../../../common-components/step-loader/step-loader";
import ThirdWebLogo from "../../../common-components/thirdweb-logo/thirdweb-logo";

import { Ethereum, Goerli } from "@thirdweb-dev/chains";
import { useAddress, useConnect } from "@thirdweb-dev/react";
import { metamaskWallet, coinbaseWallet, trustWallet, rainbowWallet, walletConnect } from "@thirdweb-dev/react";
import { ThirdWebClientID } from "../../../../../constants/common-constants";
import { useWalletAddress } from "../../../../../hooks/useWalletAddress";
import StepButton from "../../../common-components/step-button/step-button";
import { ThirdWebChain, CoinbaseDeepLinkURL } from "../../../../../constants/common-constants";

const walletConnectConfig = walletConnect();
const coinbaseConfig = coinbaseWallet({ qrmodal: "custom" });
const metamaskWalletConfig = metamaskWallet({ connectionMethod: "metamaskBrowser" });
const trustWalletConfig = trustWallet();
const rainbowWalletConfig = rainbowWallet();

function LaunchStep2({ artistId, membershipId, stepChange, disableAllowlist }) {
  const connect = useConnect();
  const [manualRefresh, setManualRefresh] = useState(1); // refresh for useWalletAddress
  const address = useAddress();
  const [loading, setLoading] = useState(false);
  const [walletConnectButtonClicked, setWalletConnectButtonClicked] = useState(false);

  const getMemberDetails = async () => {
    setLoading(true);
    const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
    // console.log("launch.js/step3.js member details>>>>>>", getMemberRes);
    if (getMemberRes["success"] && getMemberRes["code"] === 200) {
      stepChange(1);
      setLoading(false);
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
  };

  const encodeURL = (url) => {
    const urlParts = url.split(/(\?|&|=)/);
    const encodedParts = urlParts.map((part) => (part.match(/(\?|&|=)/) ? part : encodeURIComponent(part)));
    return encodedParts.join("");
  };

  const handleConnect = async (wallet) => {
    setWalletConnectButtonClicked(true);
    try {
      let w;
      if (wallet === "Metamask") {
        w = await connect(walletConnectConfig, ThirdWebChain);
      } else if (wallet === "Coinbase") {
        let encodedUrl = encodeURL(window.location.href);
        let url = `${CoinbaseDeepLinkURL}${encodedUrl}`;
        window.open(url, "_self");
        stepChange(1);
      } else if (wallet === "Rainbow") {
        w = await connect(rainbowWalletConfig, ThirdWebChain);
      } else if (wallet === "Trust-Wallet") {
        w = await connect(trustWalletConfig, ThirdWebChain);
      } else if (wallet === "Wallet-Connect") {
        w = await connect(walletConnectConfig, ThirdWebChain);
      }
      setWalletConnectButtonClicked(false);
    } catch (error) {
      // console.log("error >>>>>>>>>>", error);
      setWalletConnectButtonClicked(false);
    }
  };

  useEffect(() => {
    if (disableAllowlist) {
      stepChange(1);
    }
    if (address) {
      getMemberDetails();
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="mobile-launch-step3-container">
          <div className="mobile-connect-wallet-container">
            {walletConnectButtonClicked ? (
              <div className="mobile-launch-wallet-connecting-container">
                <div className="mobile-launch-wallet-connecting-text">
                  Please complete your wallet signin. Signing helps us know that you are the owner of the wallet
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mobile-launch-connect-wallet-text-container">
              <div className="mobile-launch-connect-wallet-text">Choose your wallet</div>
            </div>
            <div className="mobile-launch-connect-wallet-option-container" onClick={() => handleConnect("Metamask")}>
              <img
                className="mobile-connect-wallet-option-image"
                src={require("../../../../../assets/metamask.webp")}
                alt="metamask-logo"
              />
            </div>
            <div className="mobile-launch-connect-wallet-option-container" onClick={() => handleConnect("Coinbase")}>
              <img
                className="mobile-connect-wallet-option-image"
                src={require("../../../../../assets/coinbase.webp")}
                alt="coinbase-logo"
              />
            </div>
            <div
              className="mobile-launch-connect-wallet-option-container"
              onClick={() => handleConnect("Wallet-Connect")}
            >
              <img
                className="mobile-connect-wallet-option-image"
                src={require("../../../../../assets/wallet-connect.webp")}
                alt="wallet-connect-logo"
              />
            </div>

            <div className="mobile-launch-step3-thirdweb-logo-container">
              <ThirdWebLogo width={"130px"} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LaunchStep2;
